/* eslint-disable import/prefer-default-export */
export const formTemplates = [
  // {
  //   title: '单列表单',
  //   imgUrl: 'https://ks3-cn-beijing.ksyuncs.com/vform-static/form-samples/t1.png',
  //   jsonUrl: 'https://ks3-cn-beijing.ksyuncs.com/vform-static/form-samples/json1.txt',
  //   description: '表单模板详细说明...',
  // },
  {
    title: 'ฟอร์มลงทะเบียน',
    imgUrl: 'https://yru.1day.me/images/form_liff/tmp1.png',
    jsonUrl: 'https://yru.1day.me/images/form_liff/tmp1.txt',
    description: 'แบบฟอร์มลงทะเบียนผู้ใช้งาน...',
  },
  {
    title: 'แบบสำรวจความคิดเห็น',
    imgUrl: 'https://yru.1day.me/images/form_liff/tmp2.png',
    jsonUrl: 'https://yru.1day.me/images/form_liff/tmp2.txt',
    description: 'แบบสำรวจความคิดเห็น...',
  },
  {
    title: 'Promotion',
    imgUrl: 'https://yru.1day.me/images/form_liff/tmp3.png',
    jsonUrl: 'https://yru.1day.me/images/form_liff/tmp3.txt',
    description: 'Promotion...',
  },

]
